import React from 'react';
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import {useContactInfo} from '../../utils/getContactInfo';

const NewFooter = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();


  // Just after a page load call post-message from parent to the iframe!
  setTimeout(()=>{
    let iframeWindow = window.frames?.length ? window.frames.myiFrame : null;
    if(iframeWindow){
      iframeWindow.postMessage(window.location.search,'*');
    }
  },2000);

  return (
    <footer className="bg-black pb-14 pt-20">
      <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
        <div className="text-white">
          <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
          <p className="text-sourci-pink text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
          <p className="text-3xl mb-2">
            <a href="tel:+611300768724"
              className="hover:underline">{useContactInfo().getContactNumber()}</a>
          </p>
          <p className="text-3xl mb-2">
            <a href={`mailto:${useContactInfo().getEmailAddress()}`}
              className="hover:underline">{useContactInfo().getEmailAddress()}</a>
          </p>
        </div>
        <div className="mt-12 lg:mt-0">
                       <iframe title="contactIframe" 
                       src={"https://sourci-pty-ltd.odoo.com/"} 
                       style={{border:"0px #ffffff none"}} 
                       name="myiFrame" 
                       id="myiFrame"
                       scrolling="no" 
                       frameborder="1" 
                       marginHeight="0px" 
                       marginWidth="0px" 
                       height="615px"
                       width="500px" 
                       >
                       </iframe>
        </div>
        <div className='col-span-2 text-center mt-28 mb-4'>
          <StaticImage src="../../images/sourci_logo_white.png"
            alt="Sourci Logo"
            placeholder="blurred"
            width={180}
            className="mb-8" />
          <p className="text-white text-sm mb-4">© {year} Sourci - {useContactInfo().getSingular()}'s leading sourcing solution company. All Rights Reserved. <Link to="/privacy-policy" className="underline">Privacy Policy</Link></p>
          <p className="text-white text-sm">HQ: {useContactInfo().getAddress(1)} {useContactInfo().getAddress(2)} {useContactInfo().getAddress(3)}</p>
        </div>
        <div className='col-span-2 text-center'>
          <p className="text-white text-sm lg:w-1/2 lg:mx-auto">In the spirit of reconciliation Sourci acknowledges the Traditional Custodians of country throughout Australia and their connections to land, sea and community. We pay our respect to their Elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today.</p>
        </div>
      </div>
    </footer>
  )
}

export default NewFooter;