import {graphql, useStaticQuery} from 'gatsby';

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl,
          country
        }
      }
      favi:file(name: {eq: "favi"}, sourceInstanceName: {eq: "images"}) {
        publicURL
      },
      favi180:file(name: {eq: "favi-180x180"}, sourceInstanceName: {eq: "images"}) {
        publicURL
      },
      favi192:file(name: {eq: "favi-192x192"}, sourceInstanceName: {eq: "images"}) {
        publicURL
      },
      favi270:file(name: {eq: "favi-270x270"}, sourceInstanceName: {eq: "images"}) {
        publicURL
      }
    }
  `)

  return {
    ...data.site.siteMetadata,
    favi: data.favi.publicURL,
    favi180: data.favi180.publicURL,
    favi192: data.favi192.publicURL,
    favi270: data.favi270.publicURL
  };
}