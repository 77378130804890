import React, { useEffect, useState } from "react";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import ShortFooter from "./layout/ShortFooter";
import LandingFooter from "./layout/LandingFooter";
import { GTMBody } from "./layout/GoogleTags";
import NewFooter from "./layout/NewFooter";

const getPageType = (path) => {
  const parts = path.split("/");
  switch (parts[1]) {
    case "contact":
      return "contact";
    case "footertest":
      return "footertest";
    case "projects":
      return parts[2]?.length > 1 ? "project" : "default";
    case "lp":
    case "landing":
      return "landing";
    case "internationalsourcingexpo":
      return "internationalsourcingexpo";
    case "partners":
      return parts[2]?.length > 1 ? "custom-landing" : "default";
    case "ad":
      return "project";
    case "new-lp":
      return "new-lp";
    case "lp-id":
      return "lp-id";
    default:
      return "default";
  }
};

const FooterComponent = ({ type }) => {
  switch (type) {
    case "contact":
    case "custom-landing":
    case "landing":
      return <ShortFooter />;
    case "project":
      return <LandingFooter />;
    case "internationalsourcingexpo":
      return (
        <LandingFooter color="bg-gradient-to-b from-[#00C9C9] to-[#FF6CBF]" />
      );
    case "new-lp":
    case "lp-id":
      return <ShortFooter />;

    case "footertest":
      return <NewFooter />;
    default:
      return <Footer />;
  }
};

const Layout = ({ path, children }) => {
  const pageType = getPageType(path);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (!window._hsq) return;
    window._hsq.push(["setPath", path]);
    window._hsq.push(["trackPageView"]);
  }, [path]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <React.StrictMode>
      <GTMBody />
      {hasMounted && (
        <>
          {pageType !== "landing" &&
            pageType !== "custom-landing" &&
            pageType !== "internationalsourcingexpo" &&
            pageType !== "new-lp" &&
            pageType !== "lp-id" && <Navbar />}
          {children}
          <FooterComponent type={pageType} />
        </>
      )}
    </React.StrictMode>
  );
};

export default Layout;
