import React from 'react';
import clsx from 'clsx';
import {Link} from 'gatsby';

const Button = ({
  type = 'button',
  to,
  label,
  className,
  padding = 'px-14 py-1.5',
  radius="rounded-full",
  wrap = false,
  ...props
}) => {
  className = clsx('inline-flex justify-center', radius, className, padding, {
    'whitespace-nowrap': !wrap
  });
  switch (type) {
    case 'external':
      return (
        <a href={to}
          className={className}
          {...props}>
          {label}
        </a>
      )
    case 'link':
      return (
        <Link to={to}
          className={className}
          {...props}>
          {label}
        </Link>
      )
    default:
      return (
        <button type={type}
          className={className}
          {...props}>
          {label}
        </button>
      )
  }
}

export default Button;