import {graphql, useStaticQuery} from 'gatsby';

export const useGatsbyEnv = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          env
        }
      }
    }
  `)

  return data.site.siteMetadata.env;
}