import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';
import { globalHistory } from '@reach/router'

const NavItem = ({item}) => {
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();
  useEffect(() => {
    return globalHistory.listen(({action}) => {
      if(action === 'PUSH') setDropdown(false)
    })
  }, [setDropdown]);
  useEffect(() => {
    const handler = (event) => {
      if(dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    }
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown])
  return (
    <li className="relative"
      ref={ref}>
      {item.children ? (
        <>
          <button type="button"
            className={clsx('px-1 pt-2 flex justify-between items-center text-gray-700 hover:bg-transparent border-0 hover:text-sourci-teal transition-colors lg:p-0 w-auto whitespace-nowrap', {
              'pb-2': !dropdown
            })}
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}>
            <span>{item.label}</span>
            <svg className="ml-1 w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
          <div className={clsx('z-10 w-auto font-normal lg:bg-white lg:rounded divide-y divide-gray-100 lg:shadow-lg lg:absolute min-w-[120px] lg:mt-1', {
            'hidden': !dropdown,
            'block': dropdown
          })}>
            <ul className="py-1 text-gray-700">
              {item.children.map((i,k) => {
                return (
                  <li key={k}
                    className="whitespace-nowrap">
                    <Link to={i.to} 
                      className="block py-2 px-4 hover:text-sourci-teal transition-colors">
                      {i.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      ) : (
        <Link className="px-1 py-2 block text-gray-700 hover:bg-transparent border-0 hover:text-sourci-teal transition-colors whitespace-nowrap lg:p-0"
          to={item.to}>
          {item.label}
        </Link>
      )}
    </li>
  )
}

export default NavItem;