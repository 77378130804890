import { useSiteMetadata } from "../hooks/use-site-metadata"

const AU1 = {
  shortHeadquarters: 'Melbourne',
  headquarters: 'Melbourne, Australia',
  address: {
      line1: 'Level 5, 600 Church St,',
      line2: 'Cremorne VIC 3121',
      line3: 'Australia',
      link: 'https://g.page/Sourci',
  },
  emailAddress: {
      text: 'hello@sourci.com.au',
      link: 'mailto:hello@sourci.com.au',
  },
  showContactNumber: true,
  contactNumber: {
      text: '1300 SOURCI',
      link: 'tel:+611300768724',
  },
  plural: 'Australian',
  singular: 'Australia',
}

const US1 = {
  shortHeadquarters: 'California',
  headquarters: 'California, US',
  address: {
      line1: '1520 N. Mountain Avenue, Suite 202',
      line2: 'Ontario, CA 91762',
      line3: 'USA',
      link: 'https://goo.gl/maps/dAbNGjLUYhDf4ZQ76',
  },
  emailAddress: {
      text: 'hello@sourci.com',
      link: 'mailto:hello@sourci.com',
  },
  showContactNumber: false,
  contactNumber: {
      text: '',
      link: '',
  },
  plural: 'American',
  singular: 'America',
}

const NZ1 = {
  shortHeadquarters: 'Melbourne',
  headquarters: 'Melbourne, Australia',
  address: {
      line1: 'Level 5, 600 Church St,',
      line2: 'Cremorne VIC 3121',
      line3: 'Australia',
      link: 'https://g.page/Sourci',
  },
  emailAddress: {
      text: 'hello@sourci.co.nz',
      link: 'mailto:hello@sourci.co.nz',
  },
  showContactNumber: true,
  contactNumber: {
      text: '+61 1300 SOURCI',
      link: 'tel:+611300768724',
  },
  plural: 'Australian',
  singular: 'Australia',
}

export const useContactInfo = () => {
  const countryCode = useSiteMetadata().country;
  return {
    get() {

      switch (countryCode) {
          case 'US1':
              return US1
          case 'NZ1':
              return NZ1
          case 'AU1':
          default:
              return AU1
      }
    },
    getAddress(lineNumber) {
      return this.get().address[`line${lineNumber}`]
    },
    getMapLink() {
      return this.get().address.link
    },
    getEmailLink() {
      return this.get().emailAddress.link
    },
    getEmailAddress() {
      return this.get().emailAddress.text
    },
    getContactNumber() {
      return this.get().contactNumber.text
    },
    getContactLink() {
      return this.get().contactNumber.link
    },
    getPlural() {
      return this.get().plural
    },
    getSingular() {
      return this.get().singular
    },
    getHeadquarters() {
      return this.get().headquarters
    },
    showContactNumber() {
      return this.get().showContactNumber
    },
    getShortHeadquarters() {
      return this.get().shortHeadquarters
    }
  }
}