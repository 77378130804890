import React from "react";
import { useGatsbyEnv } from "../../hooks/use-gatsby-env";
import {Script} from 'gatsby';

export function GTMHead() {
  const env = useGatsbyEnv();
  if(env !== 'production') {
    return null;
  }
  return (
      <Script
          dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NZ46GQP');`,
          }}
      />
  )
}

export function GTMBody() {
  const env = useGatsbyEnv();
  if(env !== 'production') {
    return null;
  }
  return (
      <noscript>
          <iframe title="gmtag"
              src={`https://www.googletagmanager.com/ns.html?id=GTM-NZ46GQP`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
      </noscript>
  )
}